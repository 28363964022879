<template>
  <div class="order-tracking">
    <v-container grid-list-xl fluid>
      <v-row>
        <v-col cols="12">
          <v-card>
            <v-card-title>
              {{ $vuetify.lang.t('$vuetify.different.ordersTracking') }}
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <!--              <v-row>
                              <v-col cols="12" sm="6" md="2">
                                <v-menu
                                  v-model="fromPiker"
                                  :close-on-content-click="false"
                                  :nudge-right="40"
                                  transition="scale-transition"
                                  offset-y
                                  min-width="250px"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                      v-model="filter.date_start"
                                      :label="$vuetify.lang.t('$vuetify.dates.from') + '*'"
                                      prepend-icon="event"
                                      readonly
                                      v-bind="attrs"
                                      v-on="on"
                                      :rules="notEmpty"
                                    ></v-text-field>
                                  </template>
                                  <v-date-picker
                                    first-day-of-week="1"
                                    v-model="filter.date_start"
                                    @input="fromPiker = false"
                                  ></v-date-picker>
                                </v-menu>
                              </v-col>
                              <v-col cols="12" sm="6" md="2">
                                <v-menu
                                  v-model="toPiker"
                                  :close-on-content-click="false"
                                  :nudge-right="40"
                                  transition="scale-transition"
                                  offset-y
                                  min-width="250px"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                      v-model="filter.date_end"
                                      :label="$vuetify.lang.t('$vuetify.dates.to') + '*'"
                                      prepend-icon="event"
                                      readonly
                                      v-bind="attrs"
                                      v-on="on"
                                      :rules="notEmpty"
                                    ></v-text-field>
                                  </template>
                                  <v-date-picker
                                    first-day-of-week="1"
                                    v-model="filter.date_end"
                                    @input="toPiker = false"
                                  ></v-date-picker>
                                </v-menu>
                              </v-col>
                            </v-row>-->
              <v-row>
                <v-col cols="12">
                  <v-card flat>
                    <v-toolbar flat color="white">
                      <v-text-field
                        text
                        solo
                        flat
                        clearable
                        prepend-icon="search"
                        :placeholder="$vuetify.lang.t('$vuetify.toolbar.search')"
                        v-model="search"
                        hide-details
                        class="hidden-sm-and-down"
                      ></v-text-field>
                      <v-spacer></v-spacer>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            icon
                            v-on="on"
                            v-bind="attrs"
                            @click="getItems"
                          >
                            <v-icon>mdi-refresh</v-icon>
                          </v-btn>
                        </template>
                        <span>{{ $vuetify.lang.t('$vuetify.buttons.refresh') }}</span>
                      </v-tooltip>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            icon
                            v-on="on"
                            v-bind="attrs"
                            small
                            color="success"
                            @click="syncItems"
                          >
                            <v-icon>mdi-sync</v-icon>
                          </v-btn>
                        </template>
                        <span>{{ $vuetify.lang.t('$vuetify.buttons.synchronize') }}</span>
                      </v-tooltip>
                    </v-toolbar>
                    <v-divider></v-divider>
                    <v-card-text class="pa-0">
                      <v-data-table
                        :headers="compHeader"
                        :items="items"
                        fixed-header
                        class="elevation-1"
                        item-key="tracking_code"
                        :search="search"
                        :items-per-page="-1"
                        sort-by="diff_in_days"
                        sort-desc
                      >
                        <template v-slot:item.invoice_amount="{ item }">
                          {{ item.invoice_amount }} {{ item.currency }}
                        </template>
                        <template v-slot:item.action="{ item }">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                icon
                                v-on="on"
                                v-bind="attrs"
                                small
                                :disabled="item.status === 'delivered'"
                                color="success"
                                @click="syncItem(item, true)"
                              >
                                <v-icon>mdi-sync</v-icon>
                              </v-btn>
                            </template>
                            <span>{{ $vuetify.lang.t('$vuetify.buttons.synchronize') }}</span>
                          </v-tooltip>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                icon
                                v-on="on"
                                v-bind="attrs"
                                small
                                color="warning"
                                @click="hideItem(item)"
                              >
                                <v-icon>mdi-eye-off</v-icon>
                              </v-btn>
                            </template>
                            <span>{{ $vuetify.lang.t('$vuetify.buttons.hide') }}</span>
                          </v-tooltip>
                        </template>
                      </v-data-table>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog v-model="dialogHide" persistent max-width="600">
      <v-card>
        <v-card-title class="headline">{{ $vuetify.lang.t('$vuetify.different.defaultConfirm') }}
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="dialogHide = false"
            :disabled="loading"
          >
            {{ $vuetify.lang.t('$vuetify.buttons.cancel') }}
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="hideItemConfirm"
            :loading="loading"
          >{{ $vuetify.lang.t('$vuetify.buttons.ok') }}
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      :timeout="3000"
      bottom
      right
      :color="snackbar.color"
      v-model="snackbar.show"
    >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn dark text @click.native="snackbar.show = false" icon>
          <v-icon>close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <AppLoader :dialog.sync="loading"></AppLoader>
  </div>
</template>

<script>
import AppLoader from '@/components/AppLoader'

export default {
  components: {
    AppLoader
  },
  data() {
    return {
      loading: false,
      search: null,
      dialogHide: false,
      selectedItems: [],
      items: [],
      item: {
        id: null,
        hidden: false,
        tracking_id: null
      },
      defaultItem: {
        id: null,
        hidden: false,
        tracking_id: null
      },
      filter: {
        date_start: null,
        date_end: null,
        project_id: 2
      },
      selectedProject: null,
      fromPiker: false,
      toPiker: false,
      snackbar: {
        show: false,
        text: '',
        color: ''
      },
      notEmpty: [
        (v) => !!v || this.$vuetify.lang.t('$vuetify.validation.required')
      ],
      numberRule: [
        (v) => !!v || this.$vuetify.lang.t('$vuetify.validation.required'),
        (v) => parseFloat(v) >= 0.01 || this.$vuetify.lang.t('$vuetify.validation.incorrect'),
      ],
      max255: [
        (v) => !!v || this.$vuetify.lang.t('$vuetify.validation.required'),
        (v) =>
          (v && v.length <= 255) ||
          this.$vuetify.lang.t('$vuetify.validation.max255')
      ],
    }
  },
  watch: {
    filter: {
      handler() {
        this.getItems()
      },
      deep: true
    },
    dialogProfile: function (val) {
      if (!val) {
        this.item = Object.assign({}, this.defaultItem)
      }
    },
  },
  metaInfo() {
    return {
      title: this.$vuetify.lang.t('$vuetify.different.ordersTracking')
    }
  },
  mounted() {
    this.filter.date_start = this.getFromDate()
    this.filter.date_end = this.getToDate()
  },
  computed: {
    compAuthHeader() {
      return {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('access_token'),
          responseType: 'application/json'
        }
      }
    },
    compHeader() {
      return [
        {
          text: this.$vuetify.lang.t('$vuetify.order.number'),
          value: 'order_number',
        },
        {
          text: this.$vuetify.lang.t('$vuetify.order.sum'),
          value: 'invoice_amount',
        },
        {
          text: this.$vuetify.lang.t('$vuetify.order.tracking'),
          value: 'tracking_code',
        },
        {
          text: this.$vuetify.lang.t('$vuetify.dates.orderStatus'),
          value: 'change_date',
        },
        {
          text: this.$vuetify.lang.t('$vuetify.dates.days'),
          value: 'diff_in_days',
        },
        {
          text: this.$vuetify.lang.t('$vuetify.status.status'),
          value: 'status',
        },
        {
          text: this.$vuetify.lang.t('$vuetify.different.description'),
          value: 'status_description',
        },
        {
          text: this.$vuetify.lang.t('$vuetify.dates.statusDate'),
          value: 'status_date',
        },
        {
          text: this.$vuetify.lang.t('$vuetify.dates.updatedAt'),
          value: 'check_date',
        },
        {
          text: this.$vuetify.lang.t('$vuetify.buttons.action'),
          value: 'action',
          sortable: false,
          width: '6%'
        }
      ]
    }
  },
  methods: {
    getItems() {
      this.loading = true

      this.$axios
        .post(this.$store.getters.getApiUrl + 'api/order-tracking', this.filter, this.compAuthHeader)
        .then((response) => {
          if (response.status === 200) {
            this.items = response.data.items
          } else {
            this.items = []
            this.showSnackbar('red', this.$vuetify.lang.t('$vuetify.errors.error'))
          }
          this.loading = false
        })
        .catch(() => {
          this.loading = false
          this.items = []
          this.showSnackbar('red', this.$vuetify.lang.t('$vuetify.errors.error'))
        })
    },
    async syncItems() {
      if (this.items.length) {
        this.loading = true
        for (let item of this.items) {
          let syncIndex = this.items.indexOf(item)
          await this.$axios
            .post(this.$store.getters.getApiUrl + 'api/order-tracking/sync', item, this.compAuthHeader)
            .then((response) => {
              if (response.status === 200 && syncIndex > -1) {
                Object.assign(this.items[syncIndex], response.data.item)
              }
            })
        }
        this.loading = false
      }
    },
    syncItem(item, single) {
      if (single) this.loading = true
      let syncIndex = this.items.indexOf(item)

      this.$axios
        .post(this.$store.getters.getApiUrl + 'api/order-tracking/sync', item, this.compAuthHeader)
        .then((response) => {
          if (single) this.loading = false
          if (response.status === 200 && syncIndex > -1) {
            Object.assign(this.items[syncIndex], response.data.item)
          }
        })
        .catch(() => {
          if (single) {
            this.loading = false
            this.showSnackbar('red', this.$vuetify.lang.t('$vuetify.errors.error'))
          }
        })
    },
    hideItem(item) {
      this.item = Object.assign({}, item)
      this.dialogHide = true
    },
    hideItemConfirm() {
      this.loading = true
      this.$axios
        .post(this.$store.getters.getApiUrl + 'api/order-tracking/hide', {id: this.item.tracking_id}, this.compAuthHeader)
        .then((response) => {
          this.loading = false
          if (response.status === 200) {
            this.dialogHide = false
            this.showSnackbar('green', this.$vuetify.lang.t('$vuetify.messages.saved'))
            this.getItems()
          } else {
            this.showSnackbar('red', this.$vuetify.lang.t('$vuetify.errors.error'))
          }
        })
        .catch(() => {
          this.loading = false
          this.showSnackbar('red', this.$vuetify.lang.t('$vuetify.errors.error'))
        })
    },
    showSnackbar(color, text) {
      this.snackbar.show = true
      this.snackbar.color = color
      this.snackbar.text = text
    },
    getFromDate() {
      let now = new Date()
      return new Date(now.getFullYear(), now.getMonth(), now.getDate() - 6).toJSON().slice(0, 10)
    },
    getToDate() {
      return new Date().toJSON().slice(0, 10)
    },
  }
}
</script>
